import {AfterViewInit, Component, Inject, PLATFORM_ID} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {IUserInfo} from "@interfaces/authorized-user/user.interface";
import {ChildActivationEnd, Router} from "@angular/router";
import {NotificationService} from "@services/integrations/notification/notification.service";
import {GoogleTagManagerService} from "@services/marketing/google-tag-manager.service";
import {LanguageControlService} from "@services/language/language-control.service";
import {ZendeskService} from "@services/zendesk/zendesk.service";
import {ChatService} from "@services/integrations/chat/chat.service";
import {AmplitudeService} from "@services/marketing/amplitude.service";
import {environment} from "@environments/environment";
import {ConnectorV2Service} from "@services/connector/connector-v2.service";
import {isPlatformBrowser} from "@angular/common";
import {AppRedirectsService} from "@services/app-redirects/app-redirects.service";
import {skip} from "rxjs/operators";
import {UserManagerService} from "@services/user/user-manager.service";
import {NotificationManagerService} from "@services/notification-manager/notification-manager.service";
import {UserAfterLoginActionsService} from "@services/user/user-after-login-actions.service";
import {B2bHomeBannerService} from '@services/integrations/b2b-home-banner/b2b-home-banner.service';
import {CustomContentService} from '@services/custom-content/custom-content.service';
import {getAccumulatedRouteData} from "@services/routing/routing-helper/routing-helper";
import {IRoutingData} from "@services/routing/routing-helper/routing.interface";
import {EncryptionService} from "@services/request-token/encryption.service";
import {TrackingDataService} from "@services/tracking/tracking-data.service";
import {TrackingService} from "@services/tracking/tracking.service";
import commonAr from "@shared-translations/http-errors/common-ar.json";
import commonEn from "@shared-translations/http-errors/common-en.json";
import catalogAr from "@shared-translations/http-errors/catalog-ar.json";
import catalogEn from "@shared-translations/http-errors/catalog-en.json";
import {AuthV2Service} from '@services/integrations/auth-v2/auth-v2.service';

@Component({
  selector: 'alm-b2b-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

  hideMainFooter: boolean = false;
  showSimplifiedHeader: boolean = true;
  userInfoSub: BehaviorSubject<IUserInfo | null>;
  unreadNotificationCount: number = 0;
  numberOfUnreadMessages: number = 0;
  onNavigationChange: boolean = false;

  constructor(private router: Router,
              private userManagerService: UserManagerService,
              private connectorService: ConnectorV2Service,
              private notificationService: NotificationService,
              private gtmService: GoogleTagManagerService,
              private languageControl: LanguageControlService,
              private zendeskService: ZendeskService,
              private chatService: ChatService,
              private amplitudeService: AmplitudeService,
              private appRedirects: AppRedirectsService,
              @Inject(PLATFORM_ID) private platformId: any,
              private notificationManagerService: NotificationManagerService,
              private userAfterLoginActions: UserAfterLoginActionsService,
              private b2bHomeBannerService: B2bHomeBannerService,
              private customContentService: CustomContentService,
              private trackingService: TrackingService,
              private encryptionService: EncryptionService,
              private trackingData: TrackingDataService,
              private authV2Service: AuthV2Service,
              ) {
    this.userInfoSub = this.userManagerService.userInfo;
    this.languageControl.setTranslations('ar', {...commonAr,...catalogAr,});
    this.languageControl.setTranslations('en', {...commonEn,...catalogEn,});
  }

  ngAfterViewInit(): void {

    this.checkCourseViewerPresent();

    this.userInfoSubscriber();

    this.notificationNumberUpdater();

    this.scriptsLoader();

    this.connectorInitializer();

    this.userAfterLoginActions.handleDemographicsPopup();
  }

  private userInfoSubscriber() {
    if (isPlatformBrowser(this.platformId)) {
      this.userManagerService.userInfo.subscribe(user => {
        if (user) {
          this.getUnreadNotificationCount();
          this.getUnreadMessagesCount();
          this.getOrganizationInfo();

          this.trackingData.setSuperProperties({
            // set hashed name and mail for DataHash to use them for PII
            th_capi_fn: this.encryptionService.normalizeAndHashString(user.name),
            th_capi_em: this.encryptionService.normalizeAndHashString(user.email),
          });

          const anonymousUserProperties = this.trackingData.getStoredAnonymousUserProperties();
          this.trackingService.sendEvent('user_details', {
            event_properties: {
              user_uuid: user.uuid,
              user_id: user.email,
              user_name: user.name,
              subscription_type: undefined,
            },
            user_properties: anonymousUserProperties,
          });

          this.trackingData.removeStoredAnonymousUserProperties();
        }
      });
    }
  }

  private notificationNumberUpdater() {
    this.notificationService.onNotificationUpdate.subscribe((value) => {
      if (this.userManagerService.userInfo.getValue()) {
        this.getUnreadNotificationCount();
      }
    });

    this.chatService.onChatStatusUpdate.subscribe((value) => {
      if (this.userManagerService.userInfo.getValue()) {
        this.getUnreadMessagesCount();
      }
    });
  }

  private scriptsLoader() {
    this.gtmService.loadGtm(environment.gtmID);
    this.amplitudeService.loadAmplitude(environment.amplitudeApiKey, this.userInfoSub.getValue()?.uuid);
    this.zendeskService.loadZendeskWidget('09618f00-a724-4755-b153-82f91877c782');
  }

  private connectorInitializer() {
    this.connectorService.connectorLoaded.pipe(skip(1)).toPromise().then((loaded) => {
      this.notificationManagerService.initPushNotification();
      this.connectorService.connectorUser.subscribe((user) => {
        if (!user && isPlatformBrowser(this.platformId)) {
          setTimeout(() => {
            this.appRedirects.redirectToSSO('redirect-center');
          }, 500)
        }
      });
    });
  }

  checkCourseViewerPresent() {
    this.router.events.subscribe((event) => {
      if (event instanceof ChildActivationEnd) {
        const data = getAccumulatedRouteData(event);
        this.onNavigationDataChanged(data);
      }
    });
  }

  onNavigationDataChanged(data: IRoutingData) {
    this.onNavigationChange = true;
    this.hideMainFooter = data.hideMainFooter;
    this.showSimplifiedHeader = data.showSimplifiedHeader;
    if (this.hideMainFooter) {
      let support = document.getElementById('launcher');
      if (support) {
        support.style.display = 'none';
      }
    } else {
      let support = document.getElementById('launcher');
      if (support) {
        support.style.display = 'block';
      }
    }
    setTimeout(() => {
      this.onNavigationChange = false;
    }, 200);
    window.scroll(0, 0);
  }
  getOrganizationInfo(): void {
    this.b2bHomeBannerService.getB2bOrganizationInfo('b2b-home').then((organization) => {
      if(organization.hasCustomContent) {
        this.customContentService.setHasCustomContent(organization.hasCustomContent);
      }
      this.validateSubscription(organization);
    });
  }


  private getUnreadNotificationCount() {
    this.notificationService.getUnreadNotificationsCount('header-notification').then((unreadNotificationCount) => {
      this.unreadNotificationCount = unreadNotificationCount.count;
    });
  }

  private getUnreadMessagesCount() {
    this.chatService.getUnreadMsg('header-messages', true).then((unreadMessagesCount) => {
      this.numberOfUnreadMessages = unreadMessagesCount.count;
    });
  }

  validateSubscription(organization: any): void {
    const subscriptionEndTime = new Date(organization.subscriptionEndDate).getTime();
    const todayTime = new Date().getTime();
    if (subscriptionEndTime < todayTime) {
      this.authV2Service.logUserOut().subscribe(() => {
        this.connectorService.removeUser();
      });
    }
  }
}
