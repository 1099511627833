import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import packageJson from 'package.json';

import { AppModule } from './app/app.module';
import { environment } from '@environments/environment';
import * as Sentry from "@sentry/angular-ivy";

if (environment.infrastructure !== 'local') {
    Sentry.init({
        dsn: 'https://0a811c471ba644579747481ec5a8a8ea@o4505516055920640.ingest.sentry.io/4505517207781376',
        environment: environment.environmentName,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
            networkDetailAllowUrls: [environment.platforms.base.link],
            networkCaptureBodies: true
          }),
        ],
        tracesSampleRate: 0.1,
        // Session Replay
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 0.2,
        release: packageJson.sentryRelease,
    });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
