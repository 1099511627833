<alm-root-app-container [userInfoSub]="userInfoSub"
                        [removeHeader]="hideMainFooter"
                        [onNavigationChange]="onNavigationChange"
                        [unReadNotificationCount]="unreadNotificationCount"
                        [numberOfUnreadMessages]="numberOfUnreadMessages"
                        [isB2bUser]="true"
                        [hasSimplifiedHeader]="showSimplifiedHeader">
  <div class="page-wrapper" [class.viewer-wrapper]="hideMainFooter">
    <router-outlet></router-outlet>
  </div>
  <div *ngIf="!showSimplifiedHeader">
    <alm-root-footer [isCourseViewer]="hideMainFooter"
                     [userInfoSub]="userInfoSub"
                     [isB2bUser]="true"></alm-root-footer>
  </div>
  <div *ngIf="showSimplifiedHeader">
    <alm-root-simplified-footer></alm-root-simplified-footer>
  </div>
</alm-root-app-container>
<alm-root-ui-loader></alm-root-ui-loader>
