import {ILanguage, ILanguageCode, LanguageName, LanguageLocaleName} from "@interfaces/common/language.interface";
import {ELanguage, ETheme} from "@interfaces/common/connector.interface";

export const AVAILABLE_LANGUAGES: ILanguage[] = [
  {
    id: 1,
    name: LanguageName.English,
    localeName: LanguageLocaleName.English,
    code: ELanguage.EN,
    direction: "ltr",
    allowTranslationTools: true
  },
  {
    id: 2,
    name: LanguageName.Arabic,
    localeName: LanguageLocaleName.Arabic,
    code: ELanguage.AR,
    direction: "rtl",
    allowTranslationTools: false
  }
];

/* The language id for the exam question is different form the language id for the website
* we can't do something about that now as it needs a wider change
* TODO fix the language ids to match the language ids for the website (Client/Admin/Backend)
*/
export enum ExamQuestionLanguage {
  AR = 1,
  EN = 2
}

export const DEFAULT_LANGUAGE: ILanguage = AVAILABLE_LANGUAGES[1];
export const DEFAULT_LANGUAGE_CODE: ILanguageCode = DEFAULT_LANGUAGE.code;
export const DEFAULT_THEME: ETheme = ETheme.Dark;
export const DEFAULT_PAGE_TITLE = "Learning is a life's journey | almentor";
