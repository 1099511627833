import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from '@guards/auth.guard';
import {DevicesGuard} from '@guards/devices.guard';
import {
  addLanguageGuard,
  generateLanguageRoutes, getWildcardRoutes
} from "@services/routing/routing-helper/routing-helper";
import {AVAILABLE_LANGUAGES} from "@constants/ui.constants";
import {DEFAULT_HOME_PATH, NOT_FOUND_PATH} from "@constants/http.constants";
import {CustomRoutes} from "@services/routing/routing-helper/routing.interface";

const routes: CustomRoutes = [
  {
    path: 'categories',
    loadChildren: () => import('@b2b-modules/categories/categories.module')
      .then(mod => mod.CategoriesModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: true}
  },
  {
    path: 'learning-programs',
    loadComponent: () => import('@components/learning-programs/learning-programs-list/learning-programs-list.component')
      .then(mod => mod.LearningProgramsListComponent),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: true}
  },
  {
    path: 'bundles/:permanentLink',
    loadComponent: () => import('@components/learning-programs/learning-program-details/learning-program-details.component')
      .then(mod => mod.LearningProgramDetailsComponent),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: true}
  },
  {
    path: 'courses',
    loadChildren: () => import('@b2b-modules/courses/courses.module')
      .then(mod => mod.CoursesModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: true}
  },
  {
    path: DEFAULT_HOME_PATH,
    loadChildren: () => import('@b2b-modules/home/home.module')
      .then(mod => mod.HomeModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: true}
  },
  {
    path: 'mentors',
    loadChildren: () => import('@b2b-modules/mentors/mentors.module')
      .then(mod => mod.MentorsModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: true}
  },
  {
    path: 'notifications',
    loadChildren: () => import('@b2b-modules/notifications/notifications.module')
      .then(mod => mod.NotificationsModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: false}
  },
  {
    path: 'partners',
    loadChildren: () => import('@b2b-modules/partners/partners.module')
      .then(mod => mod.PartnersModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: true}
  },
  {
    path: 'search',
    loadChildren: () => import('@b2b-modules/search/search.module')
      .then(mod => mod.SearchModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: true}
  },
  {
    path: 'messages',
    loadChildren: () => import('@b2b-modules/user-messages/user-messages.module')
      .then(mod => mod.UserMessagesModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: false}
  },
  {
    path: 'settings',
    loadChildren: () => import('@b2b-modules/user-settings/user-settings.module')
      .then(mod => mod.UserSettingsModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: false}
  },
  {
    path: 'saved-list',
    loadChildren: () => import('@b2b-modules/saved-list/saved-list.module')
      .then(mod => mod.SavedListModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: false}
  },
  {
    path: 'profile',
    loadChildren: () => import('@b2b-modules/user-profile/user-profile.module')
      .then(mod => mod.UserProfileModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: false}
  },
  {
    path: 'devices',
    loadChildren: () => import('@b2b-modules/device-management/device-management.module')
      .then(mod => mod.DeviceManagementModule),
    canActivate: [DevicesGuard],
    data: {
      redirectToAnonymous: false,
      showSimplifiedHeader: true
    }
  },
  {
    path: 'user-info',
    loadComponent: () => import('@authorized-modules/user-info/user-info.component')
      .then(mod => mod.UserInfoComponent),
    data: {
      showSimplifiedHeader: true,
    }
  },
  {
    path: 'my-progress',
    loadChildren: () => import('@b2b-modules/my-progress/my-progress.module')
      .then(mod => mod.MyProgressModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: false}
  },
  {
    path: 'my-certificates',
    loadChildren: () => import('@b2b-modules/my-certificates/my-certificates.module')
      .then(mod => mod.MyCertificatesModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: false}
  }, {
    path: 'certificate',
    loadChildren: () => import('@pages/certificate-page/certificate-page.module')
      .then(mod => mod.CertificatePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'my-notes',
    loadChildren: () => import('@b2b-modules/my-notes/my-notes.module')
      .then(mod => mod.MyNotesModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: false}
  },
  {
    path: 'my-courses',
    loadChildren: () => import('@b2b-modules/my-courses/my-courses.module')
      .then(mod => mod.MyCoursesModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: false}
  },
  {
    path: 'assigned-courses',
    loadChildren: () => import('@b2b-modules/assigned-courses/assigned-courses.module')
      .then(mod => mod.AssignedCoursesModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: false}
  },
  {
    path: 'viewer',
    loadChildren: () => import('@course-viewer/course-viewer.module')
      .then(mod => mod.CourseViewerModule),
    canActivate: [AuthGuard],
    data: {
      redirectToAnonymous: true,
      hideMainFooter: true,
    }
  },
  {
    path: 'invite-to-organization',
    loadComponent: () => import('@pages/migration-invitation/migration-invitation.component')
        .then(mod => mod.MigrationInvitationComponent),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: true}
  },
  {
    path: NOT_FOUND_PATH,
    loadChildren: () => import('@b2b-modules/not-found/not-found.module')
      .then(mod => mod.NotFoundModule),
    canActivate: [AuthGuard],
    data: {redirectToAnonymous: true}
  },
];

addLanguageGuard(routes);
const languageRoutes: Routes = generateLanguageRoutes(AVAILABLE_LANGUAGES, routes);
const wildcardRoutes: Routes = getWildcardRoutes(DEFAULT_HOME_PATH, AVAILABLE_LANGUAGES);

@NgModule({
  imports: [
    RouterModule.forRoot([
      ...routes,
      ...languageRoutes,
      ...wildcardRoutes
    ], {
    onSameUrlNavigation: 'ignore',
    scrollPositionRestoration: 'top',
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
